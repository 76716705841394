export const API_URL = 'https://api.xanhduong.vn';
export const WEB_APP_URL = 'https://wap.xanhduong.vn';

export const TOKEN_AUTH_KEY = 'blueprinter-webmobile-token';
export const USER_AUTH_KEY = 'blueprinter-webmobile-user';
export const PERMISSION_AUTH_KEY = 'blueprinter-webmobile-permission';
// export const COOKIES_TOKEN_KEY = 'blueprinter-webmobile-token';
// export const COOKIES_USER_KEY = 'blueprinter-webmobile-user';
export const LANGUAGE_KEY = 'blueprinter-webmobile-lang';

export const ROLE_ASSITANT = 'assistant';
export const ROLE_USER = 'user';

export const LANG_EN = 'en';
export const LANG_VN = 'vn';

export const THEME_MAIN = 'main'; //antd
export const THEME_TLN = 'tln'; //tln https://www.figma.com/file/OXJ7PAodiEbH9vPOMsee9n/Design-system-export-in-0104-(1)
export const THEME_KAOLA = 'kaola';

export const srcScanCaptureAudio = 'single_beep_01.mp3';
export const srcSingleBeep00Audio = 'single_beep.mp3';
export const srcSingleBeep01Audio = 'single_beep_01.mp3';
export const srcSingleBeep06Audio = 'single_beep_06.mp3';
export const srcErrorScanAudio = 'error_beep_01.mp3';
export const srcErrorBeep01Audio = 'error_beep_01.mp3';

// export const themeMainSetting = {
//   colorPrimary: blue.primary || '#1677ff',
//   colorInfo: blue.primary || '#1677ff',
//   colorError: red.primary || '#ff4d4f',
//   colorSuccess: '#52c41a',
//   colorWarning: '#faad14',
// };

export const themeKaolaSetting = {
  colorPrimary: '#51b8f1',
  colorInfo: '#51b8f1',
  colorError: '#e55c5c',
  colorSuccess: '#58a732',
  colorWarning: '#f0a818',
};

export const themeTlnSetting = {
  colorPrimary: '#9E77ED',
  colorInfo: '#51b8f1',
  colorError: '#F04438',
  colorSuccess: '#17B26A',
  colorWarning: '#F79009',
};
export const STREAMING_PROCESS = {
  START: 'START',
  COMPLETE: 'COMPLETE',
};
export const FeedBackStatusEnum = {
  request: 'request',
  approve: 'approve',
  deny: 'deny',
};

export const APP_VERSION_CONST = 119;
export const MODULE_DEFINE = {
  //  vat tu cuon
  'mobile.kho.kho-cuon-ao': {
    screen: 'kho_nhapKhoCuonAo',
    icon: 'indent', // FontAwesome
  },
  'mobile.kho.kho-cuon-chinh': {
    screen: 'kho_nhapKhoCuonChinh',
    icon: 'th-large', // FontAwesome
  },

  //Vat tu khac
  'mobile.kho.kho-khac-ao': {
    screen: 'kho_nhapKhoKhacAo',
    icon: 'indent', // FontAwesome
  },
  'mobile.kho.kho-khac-chinh': {
    screen: 'kho_nhapKhoKhacChinh',
    icon: 'th-large', // FontAwesome
  },
  // kho thanh pham
  'mobile.kho.kho-thanh-pham': {
    screen: 'kho_nhapKhoThanhPham',
    icon: 'tasks', // FontAwesome
  },

  'mobile.kho.giao-hang': {
    title: 'Giao hàng',
    screen: 'kho_giaoHang',
    icon: 'car', // FontAwesome
  },

  'mobile.kho.xuat-kho-vat-tu-cuon': {
    title: 'Xuất Kho Vật Tư Cuộn',
    screen: 'kho_xuatKhoVatTuCuon',
    icon: 'angle-double-right', // FontAwesome
  },

  'mobile.kho.xuat-kho-vat-tu-khac': {
    title: 'Xuất Kho Vật Tư Khác',
    screen: 'kho_xuatKhoVatTuKhac',
    icon: 'angle-double-right', // FontAwesome
  },

  'mobile.kho.cap-nhat-vi-tri-kho': {
    title: 'Thay đổi vị trí kho',
    screen: 'kho_thayDoiViTriKho',
    icon: 'inbox', // FontAwesome
  },
};

export const DON_VI_TINH = [
  {
    code: 'CM',
    title: 'cm',
  },
  {
    code: 'CM2',
    title: 'cm2',
  },
  {
    code: 'M',
    title: 'm',
  },
  {
    code: 'M2',
    title: 'm2',
  },
  {
    code: 'INCH',
    title: 'inch',
  },
  {
    code: 'TAM',
    title: 'Tấm',
  },
  {
    code: 'TO',
    title: 'Tờ',
  },
  {
    code: 'RAM',
    title: 'ram',
  },
  {
    code: 'KG',
    title: 'kg',
  },
  {
    code: 'G',
    title: 'g',
  },
  {
    code: 'LIT',
    title: 'lít',
  },
  {
    code: 'HOP',
    title: 'hộp',
  },
  {
    code: 'CAI',
    title: 'cái',
  },
  {
    code: 'KGM2',
    title: 'kg/m2',
  },
];

export const COLORS_GREEN = '#14A44D';
export const COLORS_GREEN_LIGHT = '#88cc88';
export const COLORS_BLUE = '#3B71CA';
export const COLORS_BLUE_LIGHT = '#54B4D3';
export const COLORS_RED = '#DC4C64';
export const COLORS_WARN = '#E4A11B';
export const COLORS_WARN_LIGHT = '#fceab6';
export const COLORS_BLACK = '#000000';
export const COLORS_BLACK_LIGHT = '#332D2D';
export const COLORS_WHITE_LIGHT = '#FBFBFB';
export const COLORS_WHITE = '#ffffff';
export const COLORS_GRAY = '#9FA6B2';

export const COLORS_LIST = [COLORS_BLUE, COLORS_GRAY, COLORS_GREEN, COLORS_RED, COLORS_WARN, COLORS_BLUE_LIGHT, COLORS_BLACK_LIGHT, COLORS_WHITE_LIGHT];

export const VatTuKhoState = {
  // PO, WAITING, READY, ASSIGNED, USING, USED, ERROR, DELETED
  READY: 'READY',
  WAITING: 'WAITING',
};
