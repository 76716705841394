import { Html5QrcodeScanner } from 'html5-qrcode';
import { useEffect } from 'react';

const qrcodeRegionId = 'html5qr-code-full-region';

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
  let config = {
    fps: 2,
  };
  if (props.fps !== undefined) {
    config.fps = props.fps;
  }
  if (props.qrbox !== undefined) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio !== undefined) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  if (props.supportedScanTypes !== undefined) {
    config.supportedScanTypes = props.supportedScanTypes;
    // [
    //   Html5QrcodeScanType.SCAN_TYPE_CAMERA,
    //   // Html5QrcodeScanType.SCAN_TYPE_FILE
    // ]
  } else {
    config.supportedScanTypes = [
      Html5QrcodeScanType.SCAN_TYPE_CAMERA,
      // Html5QrcodeScanType.SCAN_TYPE_FILE
    ];
  }
  if (props.formatsToSupport !== undefined) {
    config.formatsToSupport = props.formatsToSupport;
    // [
    //   Html5QrcodeSupportedFormats.QR_CODE,
    //   Html5QrcodeSupportedFormats.UPC_A,
    //   Html5QrcodeSupportedFormats.UPC_E,
    //   Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
    //   Html5QrcodeSupportedFormats.CODE_128,
    //   Html5QrcodeSupportedFormats.CODE_39,
    //   Html5QrcodeSupportedFormats.CODE_93,
    //   Html5QrcodeSupportedFormats.EAN_13,
    //   Html5QrcodeSupportedFormats.EAN_8,
    //   Html5QrcodeSupportedFormats.CODABAR,
    // ]
  }
  if (props.rememberLastUsedCamera !== undefined) {
    config.rememberLastUsedCamera = props.rememberLastUsedCamera;
  }
  return config;
};

const Html5QrcodePlugin = (props) => {
  const { qrCodeSuccessCallback, qrCodeErrorCallback } = props;
  useEffect(() => {
    // when component mounts
    const config = createConfig(props);
    const verbose = props.verbose === true;
    // Suceess callback is required.
    if (!props.qrCodeSuccessCallback) {
      throw 'qrCodeSuccessCallback is required callback.';
    }
    if (!props.qrCodeErrorCallback) {
      throw 'qrCodeErrorCallback is required callback.';
    }
    const html5QrcodeScanner = new Html5QrcodeScanner(qrcodeRegionId, config, verbose);
    html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);

    // cleanup function when component will unmount
    return () => {
      html5QrcodeScanner.clear().catch((error) => {
        console.error('Failed to clear html5QrcodeScanner. ', error);
        alert(`Can not cleanup Html5QrcodePlugin` + (error?.message || JSON.stringify(error)));
      });
    };
  }, []);

  return (
    <div
      id={qrcodeRegionId}
      // style={{
      //   width: window.innerWidth+'px',
      //   height:'450px'
      // }}
    />
  );
};

export default Html5QrcodePlugin;
