export const logInfo = (...args: any) => {
  const stylesInfo = [
    'color: blue',
    'background: #b0d8ff',
    'font-size: 16px',
    // 'border: 1px solid red',
    // 'text-shadow: 2px 2px black',
    'padding: 2px',
  ].join(';');
  let messageConfig = '%c%s ';

  args.forEach((argument: any) => {
    const type = typeof argument;
    switch (type) {
      case 'bigint':
      case 'number':
      case 'boolean':
        messageConfig += '%d ';
        break;

      case 'string':
        messageConfig += '%s ';
        break;

      case 'object':
      case 'undefined':
      default:
        messageConfig += '%o ';
    }
  });

  console.info(messageConfig, stylesInfo, '[Info]', ...args);
};

export const logSuccess = (...args: any) => {
  const stylesInfo = [
    'color: green',
    `background: #b9ffc5`,
    'font-size: 16px',
    // 'border: 1px solid red',
    // 'text-shadow: 2px 2px black',
    'padding: 2px',
  ].join(';');
  let messageConfig = '%c%s ';

  args.forEach((argument: any) => {
    const type = typeof argument;
    switch (type) {
      case 'bigint':
      case 'number':
      case 'boolean':
        messageConfig += '%d ';
        break;

      case 'string':
        messageConfig += '%s ';
        break;

      case 'object':
      case 'undefined':
      default:
        messageConfig += '%o ';
    }
  });

  console.log(messageConfig, stylesInfo, '[Success]', ...args);
};
export const logError = (...args: any) => {
  const stylesInfo = [
    'color: red',
    `background: #fec8c8`,
    'font-size: 16px',
    // 'border: 1px solid red',
    // 'text-shadow: 2px 2px black',
    'padding: 2px',
  ].join(';');
  let messageConfig = '%c%s ';

  args.forEach((argument: any) => {
    const type = typeof argument;
    switch (type) {
      case 'bigint':
      case 'number':
      case 'boolean':
        messageConfig += '%d ';
        break;

      case 'string':
        messageConfig += '%s ';
        break;

      case 'object':
      case 'undefined':
      default:
        messageConfig += '%o ';
    }
  });

  console.error(messageConfig, stylesInfo, '[Error]', ...args);
};

export const logWarn = (...args: any) => {
  const stylesInfo = [
    'color: #faad14',
    `background: #ffe5c4`,
    'font-size: 16px',
    // 'border: 1px solid red',
    // 'text-shadow: 2px 2px black',
    'padding: 2px',
  ].join(';');
  let messageConfig = '%c%s ';

  args.forEach((argument: any) => {
    const type = typeof argument;
    switch (type) {
      case 'bigint':
      case 'number':
      case 'boolean':
        messageConfig += '%d ';
        break;

      case 'string':
        messageConfig += '%s ';
        break;

      case 'object':
      case 'undefined':
      default:
        messageConfig += '%o ';
    }
  });

  console.warn(messageConfig, stylesInfo, '[Warn]', ...args);
};
