import './public-path';
import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { store } from './reduxs/store';
import App from './App';
import { RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom';
ReactDOM.render(
  <StrictMode>
    <Provider
      store={store}
      // children={ <App />}
    >
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// /// react 18 createRoot
// const rootElement = document.getElementById('root');
// const root = createRoot(rootElement);
// root.render(
//   // <React.StrictMode>
//   <StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </StrictMode>
//   // </React.StrictMode>,
// );
