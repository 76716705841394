// import { getFingerprint } from '@thumbmarkjs/thumbmarkjs';

import { Toast } from 'antd-mobile';
import { WEB_APP_URL, srcErrorScanAudio } from '../consts/constApp';

export const randomNumber = (minimum: any, maximum: any) => {
  return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
};
export const isLocalhost = () => {
  return window.location.href.includes('localhost');
};
export const timeout = (sec: any) => {
  return new Promise((res, rej) => {
    setTimeout(() => {
      res(null);
    }, sec * 1000);
  });
};

export const playAudioFileName = async (fileName = 'single_beep_01.mp3') => {
  if (!window['customAudio']) {
    window['customAudio'] = document.createElement('audio');
  }
  let ele: HTMLAudioElement = window['customAudio'] || document.createElement('audio');
  ele.src = `${WEB_APP_URL}/sounds/${fileName}`;
  try {
    if (ele.canPlayType('audio/mpeg') || ele.canPlayType('audio/mp3')) {
      await ele.play();
    }
  } catch (error) {
    console.error(error);
    alert(`error play audio err= ` + error?.message);
  }
};
