import { API_URL, TOKEN_AUTH_KEY } from './../consts/constApp';
// import { convertStreamDataToList, getCurrentChatBotCache, getDeviceId } from '../utils/appHelper';
import { apiClient } from './axiosBase';

export const initWebChatApi = async ({ id }: any) => {
  const url = `/api/adm/rag/init`;
  try {
    let result = await apiClient.post(
      url,
      {
        // appId: 'appId'
      },
      {
        headers: id
          ? {
              ['x-bot-id']: id,
            }
          : {},
      }
    );
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

export const getAllChatBotApi = async ({}) => {
  const url = `/api/adm/bot-instance`;
  try {
    let result = await apiClient.get(url, {
      // appId: 'appId'
    });
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};
export const getBotByIdApi = async ({ id }: any) => {
  const url = `/api/adm/bot-instance/${id}`;
  try {
    let result = await apiClient.get(url, {
      // appId: 'appId'
      headers: {
        ['x-bot-id']: id,
      },
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

export const getListConversationApi = async ({ deviceId, page, limit }: any) => {
  try {
    let nPage = (page || 1) - 1;
    let url = `/api/adm/rag/conversation/history/device/${deviceId}`;

    url = url + `?page=${nPage}&limit=${limit || 20}`;
    let result = await apiClient.get(url);
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getListConversationOfMeApi = async ({ page, limit }: any) => {
  try {
    let nPage = (page || 1) - 1;
    let url = `/api/adm/rag/conversation/history/me`;
    url = url + `?page=${nPage}&limit=${limit || 20}`;
    let result = await apiClient.get(url);
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendFeedbackApi = async ({ payload }: any) => {
  try {
    let url = `/api/adm/rag/feedback`;
    let result = await apiClient.post(url, {
      ...payload,
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const removeConverastionApi = async ({ id }: any) => {
  try {
    let url = `/api/adm/rag/conversation/history/${id}`;
    let result = await apiClient.delete(url);
    return result.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getConversationBySessionIdApi = async ({ sessionId }: any) => {
  try {
    let url = `/api/adm/rag/conversation/history/session/${sessionId}`;
    let result = await apiClient.get(url);
    return result.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postSendMessageApi = async ({ content, botId, sessionId }: { content?: string; botId?: string; sessionId?: string }) => {
  try {
    let url = `/api/adm/rag/chat`;
    let result = await apiClient.post(
      url,
      {
        appId: botId,
        instanceId: botId,
        botId,
        msg: content,
        ...(sessionId ? { sessionId } : {}),
      },
      {
        headers: {
          // ['x-org-id']: botId,
          // ['x-did']: getDeviceId(),
          // ['x-bot-id']: botId,
        },
      }
    );
    return result.data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateUserProfileApi = async (id: string, payload: any) => {
  //search?q=rio
  const url = `/api/adm/users/${id}`;
  try {
    let result = await apiClient.put(url, {
      // appId: 'appId'
      ...(payload || {}),
      id,
    });
    // console.log(url, result.data.data);
    return result.data.data;
  } catch (error) {
    console.error(url, error);
    throw error;
  }
};

// export const submitPlayVoiceAudio = async ({ botId, content }:any) => {
//   let resContentType = '';
//   try {
//     return await new Promise((resolve, reject) => {
//       var xhr = new XMLHttpRequest();
//       xhr.open('POST', API_URL + `/api/adm/rag/speak`, true);
//       xhr.responseType = 'blob';
//       xhr.setRequestHeader('Content-Type', 'application/json');
//       xhr.setRequestHeader('x-org-id', botId);
//       xhr.setRequestHeader('x-did', getDeviceId());
//       xhr.setRequestHeader('x-bot-id', botId);
//       xhr.setRequestHeader('authorization', 'Bearer ' + localStorage.getItem(TOKEN_AUTH_KEY));
//       xhr.onload = async function (e) {
//         if (this.status == 200) {
//           resContentType = xhr.getResponseHeader('Content-Type');
//           console.log(`audio resContentType`, resContentType);
//           if (resContentType !== 'application/json') {
//             var blob = this.response;
//             var blobUrl = window.URL.createObjectURL(blob);
//             resolve(blobUrl);
//             return;
//           }
//           if (resContentType === 'application/json') {
//             let blob = this.response;
//             blob = await blob.text();
//             console.log(`audio resContentType blob `, blob, typeof blob);
//             if (blob) {
//               try {
//                 blob = JSON.parse(blob);
//                 resolve(blob);
//               } catch (error) {
//                 console.log(error);
//                 reject(error);
//               }
//             }
//             // resolve(blob);
//             return;
//           }
//         }
//         console.error(`call api voice error `, e);
//         reject(e);
//         return;
//       };

//       xhr.onerror = function (e) {
//         console.error(`call api voice error `, e);
//         reject(e);
//       };
//       xhr.send(
//         JSON.stringify({
//           botId: botId,
//           text: content,
//         })
//       );
//     });
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };

// export const streamingChatApi = async ({ content, sessionId, onStreamData, onError }) => {
//   const timeoutInitStream = 1000 * 100; //100s
//   fetch(API_URL + `/api/adm/rag/chat/stream`, {
//     signal: AbortSignal.timeout(timeoutInitStream),
//     method: 'POST',
//     headers: {
//       ['x-bot-id']: getCurrentChatBotCache()?.id,
//       ['x-org-id']: '250fdf53-d80b-40c5-b6c7-40a79e6785ca'.replace(/\w/g, '0'),
//       ['x-did']: getDeviceId(),
//       ['Content-Type']: 'application/json',
//       ['authorization']: 'Bearer ' + localStorage.getItem(TOKEN_AUTH_KEY),
//     },
//     body: JSON.stringify({
//       msg: content,
//       ...(sessionId ? { sessionId } : {}),
//     }),
//   })
//     .then(async (response) => {
//       const reader = response.body.getReader();
//       let isFirstChunk = true;
//       while (true) {
//         const readerData = await reader.read();
//         const { value, done } = readerData;
//         // console.log(`reader.read() value`, { readerData, value, done });
//         if (done) {
//           // console.log(`stream complete done = ${done}`);
//           await onStreamData({
//             messages: [],
//             done,
//           });
//           break;
//         }
//         if (value) {
//           let decodeStreamData = new TextDecoder().decode(value, { stream: true });
//           let messages = convertStreamDataToList(decodeStreamData);
//           console.log(`decodeStreamData: messages `, messages);
//           await onStreamData({
//             messages: messages,
//             done,
//             ...(isFirstChunk === true ? { isFirstChunk: true } : {}),
//             sessionId: messages[0] && (messages[0] as any)?.sessionId,
//           });
//           isFirstChunk = false;
//         }
//       }
//     })
//     .catch((error) => {
//       console.error('[app.httpclient] Error init connect:', error);
//       if (error && String(error.message).includes('The user aborted a request')) {
//         console.error('[app.httpclient] Error init connect: timeout ', error);
//       }
//       if (typeof onError == 'function') {
//         onError(error);
//       }
//     });
// };

// //
// // Idea feedback
// //
// export const sendIdeaFeedbackApi = async (params = {}) => {
//   try {
//     let url = `/api/adm/rag/ending`;
//     let result = await apiClient.post(url, {
//       ...params,
//     });
//     return result.data.data;
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };
