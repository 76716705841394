// export default function Todo() {
//   return <div>ToDo</div>;
// }
import { useEffect, useState } from 'react';
import QRCodeScanReader from '../../components/qrCodeScanReader';
import Layout from '../../layout/layout';
import { Button, Space } from 'antd-mobile';
import BarCodeScanReader from '../../components/barCodeScanReader';
import Html5QrcodePlugin from '../../components/Html5QrcodePlugin';
import { playAudioFileName } from '../../utils/appHelper';
import { srcErrorScanAudio, srcScanCaptureAudio } from '../../consts/constApp';
import { Html5QrcodeScanType, Html5QrcodeSupportedFormats } from 'html5-qrcode';

export default function ToDo() {
  const [openScan, setopenScan] = useState(false);

  useEffect(() => {}, []);

  return (
    <Layout>
      <div className="todo-page">
        <div>
          <Button
            onClick={(e) => {
              setopenScan(!openScan);
            }}
          >
            {openScan ? 'Close Scan' : 'Open Scan'}
          </Button>

          <Button
            color="primary"
            onClick={() => {
              playAudioFileName(srcScanCaptureAudio);
            }}
          >
            Test play audio
          </Button>
        </div>
        {openScan && (
          <Html5QrcodePlugin
            //**fps A.K.A frame per second, the default value for this is 2, but it can be increased to get faster scanning. Increasing too high value could affect performance. Value >1000 will simply fail. */
            fps={2}
            //** qrbox Use this property to limit the region of the viewfinder you want to use for scanning. The rest of the viewfinder would be shaded. For example by passing config { qrbox : 250 } */
            qrbox={250}
            // qrbox={{
            //   width: window.innerWidth,
            //   height: 500,
            // }}

            //**rememberLastUsedCamera If true the last camera used by the user and weather or not permission was granted would be remembered in the local storage */
            rememberLastUsedCamera={true}
            // Only support camera scan type.
            supportedScanTypes={[
              Html5QrcodeScanType.SCAN_TYPE_CAMERA,
              // Html5QrcodeScanType.SCAN_TYPE_FILE
            ]}
            // formatsToSupport={[
            //   Html5QrcodeSupportedFormats.QR_CODE,
            //   Html5QrcodeSupportedFormats.UPC_A,
            //   Html5QrcodeSupportedFormats.UPC_E,
            //   Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,
            //   Html5QrcodeSupportedFormats.CODE_128,
            //   Html5QrcodeSupportedFormats.CODE_39,
            //   Html5QrcodeSupportedFormats.CODE_93,
            //   Html5QrcodeSupportedFormats.EAN_13,
            //   Html5QrcodeSupportedFormats.EAN_8,
            //   Html5QrcodeSupportedFormats.CODABAR,
            // ]}
            disableFlip={false}
            qrCodeSuccessCallback={async (decodedText, decodedResult) => {
              if (decodedText && decodedResult) {
                console.log({ decodedText, decodedResult });
                let formatName = decodedResult?.result?.format?.formatName;
                if (formatName) {
                  playAudioFileName(srcScanCaptureAudio);
                }
                alert(`Html5QrcodePlugin decodedText=` + decodedText + ` formatName=` + formatName);
              }
            }}
            qrCodeErrorCallback={async (err) => {
              let errMessage = err?.message || JSON.stringify(err);
              if (String(errMessage).includes('No MultiFormat Readers were able to detect the code')) {
                //ignore
              } else {
                alert(`error qrCodeErrorCallback ` + (err?.message || JSON.stringify(err)));
                // playAudioUtil(srcErrorScanAudio);
              }
            }}
          />
        )}
      </div>
    </Layout>
  );
}
