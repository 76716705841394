/* eslint-disable */
import axios from 'axios';
import { API_URL, TOKEN_AUTH_KEY, USER_AUTH_KEY } from './../consts/constApp';
// import { getCurrentChatBotCache, getDeviceId } from '../utils/appHelper';
import { removeLocalStorageAndCookies } from '../utils/authHelper';
const timeoutNumberMs = 60000;
const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    // 'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  timeout: timeoutNumberMs,
});
apiClient.defaults.timeout = timeoutNumberMs;

apiClient.interceptors.request.use(
  function (config) {
    if (!config.headers.authorization) {
      if (localStorage.getItem(TOKEN_AUTH_KEY)) {
        config.headers.authorization = 'Bearer ' + localStorage.getItem(TOKEN_AUTH_KEY);
      }
    }
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }

    // if (!config.headers['x-bot-id']) {
    //   config.headers['x-bot-id'] = getCurrentChatBotCache()?.id;
    // }
    // config.headers['x-did'] = getDeviceId();
    // config.headers['x-device-id'] = getDeviceId();
    // if (!config.headers['x-org-id']) {
    //   let emptyOrgId = '250fdf53-d80b-40c5-b6c7-40a79e6785ca'.replace(/\w/g, '0');
    // config.headers['x-org-id'] = getCurrentChatBotCache()?.orgId || emptyOrgId;
    // }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
apiClient.interceptors.response.use(
  function (res) {
    // console.log(res);
    return res;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log(`axios.interceptors.response`, error);
    // message.error( error.message || error.toString() );
    // console.log(error.response.data);
    // console.log(error.response.status);
    // console.log(error.response.headers);

    if (error && error.response) {
      let response = error.response;
      if (response.status === 401 || response.statusCode === 401) {
        console.error(`API 401 error=`, error);
        console.error(`API 401 response=`, response);
        removeLocalStorageAndCookies();
        let curretHref = window.location.href;
        if (String(curretHref).includes('login') == false) {
          window.location.href = '/login';
          return;
        }
        // console.log(window.location.href);
        // if(response.config && String(response.config.url).includes('/login') == false){
        //   console.log(`API 401 redirect signin`, response.config);
        //   window.location.href = "/signin";
        //   return;
        // }
      }
      if (error.response.data) {
        return Promise.reject(error.response.data);
      }
    }
    if (error && error.response && error.response.data) {
      if (error.response.data.errors) {
        if (error.response.data.errors.message) return Promise.reject(error.response.data.errors.message);
        return Promise.reject(JSON.stringify(error.response.data.errors));
      }

      if (error.response.data.error) {
        if (error.response.data.error.message) return Promise.reject(error.response.data.error.message);
        return Promise.reject(JSON.stringify(error.response.data.error));
      }

      if (error.response.data) {
        if (error.response.data.message) return Promise.reject(error.response.data.message);
        return Promise.reject(JSON.stringify(error.response.data));
      }
    }
    return Promise.reject(error);
  }
);
export { apiClient };
