import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Badge, NavBar, SideBar, Space, TabBar, Toast } from 'antd-mobile';
import { Route, useLocation, Router, createBrowserRouter, RouterProvider, useNavigate } from 'react-router-dom';
import {
  AppOutline,
  ArrowDownCircleOutline,
  CameraOutline,
  CloseOutline,
  HeartFill,
  HistogramOutline,
  InformationCircleOutline,
  LeftOutline,
  MessageOutline,
  MoreOutline,
  SearchOutline,
  UnorderedListOutline,
  UserOutline,
} from 'antd-mobile-icons';

import './styles.scss';
import Home from './pages/home/home';
import Todo from './pages/todo/todo';
import Message from './pages/message/message';
import Profile from './pages/profile/profile';
import Login from './pages/login/login';
import { selectAuthState } from './reduxs/authSlice';
import { RootState, useAppSelector } from './reduxs/store';
import { useSelector, useStore } from 'react-redux';

export default function App() {
  const authState = useSelector((state: any) => state.authState);
  const createdRoutes = createBrowserRouter([
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/home',
      element: <Home />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/profile',
      element: <Profile />,
    },
    {
      path: '/message',
      element: <Message />,
    },
    {
      path: '/todo',
      element: <Todo />,
    },
  ]);
  useEffect(() => {}, []);
  return <RouterProvider router={createdRoutes}></RouterProvider>;
}
