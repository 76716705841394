import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
// import { getUserInfo, loginByUsernamePassword } from '../apis/authApi';
import { getAuthToken, getAuthUser } from '../utils/authHelper';
import { getListConversationApi, getListConversationOfMeApi } from '../apis/appApi';
// import { getCurrentChatBotCache, setCurrentChatBotCache } from '../utils/appHelper';

export interface AppState {
  // conversationListState: {
  //   items?: any[];
  //   page: number;
  //   limit: number;
  //   total?: number;
  //   loading?: boolean;
  //   error?: any;
  // };
  // webAppInfo?: any; // init app info api
  // allChatBot?: any[];
  // currentChatBot?: any;
  // currentConversation?: any;
}

const initialState: AppState = {
  // conversationListState: {
  //   items: [],
  //   page: 1,
  //   limit: 20,
  //   loading: false,
  //   error: null,
  // },
  // webAppInfo: null,
  // allChatBot: [],
  // currentChatBot: null,
  // currentConversation: null,
};

export const getListConversationAction = createAsyncThunk(
  'conversation/getListConversationOfUser', //ten action
  async (params: any = {}, { rejectWithValue }) => {
    try {
      const response = await getListConversationOfMeApi({
        ...params,
        // deviceId: params.deviceId,
      });
      // console.log(`getListConversationOfDeviceId success`, response);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const appSlice = createSlice({
  name: 'auth',
  initialState,

  // Các action bình thường (sync action)
  reducers: {
    setListConversationSuccessAction: (state, action: PayloadAction<any>) => {
      // console.log(`login success`, action);
      // state.conversationListState.items = action.payload?.items;
      // state.conversationListState.total = action.payload?.total || 0;
      // state.conversationListState.error = null;
      // state.conversationListState.loading = false;
    },
    // appendNewConversationToList: (state, action) => {
    //   state.conversationListState.items = [action.payload, ...state.conversationListState.items];
    // },
    setWebAppInfoSuccess: (state, action) => {
      // console.log(`action setWebAppInfoSuccess`, action);
      // state.webAppInfo = action.payload;
    },
    setAllChatBotSuccess: (state, action) => {
      // state.allChatBot = action.payload;
    },
    // setCurrentChatBotAction: (state, action) => {
    //   state.currentChatBot = action.payload;
    //   setCurrentChatBotCache(action.payload);
    // },
    // setCurrentConversationAction: (state, action) => {
    //   state.currentConversation = action.payload;
    // },
  },

  // action async - Code logic xử lý async action
  extraReducers: (builder) => {
    builder
      .addCase(getListConversationAction.pending, (state, action) => {
        //// Bắt đầu thực hiện action fetchCountFromApi (Promise pending)
        console.log(`pending status`, action);
        // state.conversationListState.loading = true;
      })
      .addCase(getListConversationAction.fulfilled, (state, action) => {
        //// Khi thực hiện action fetchCountFromApi (Promise fulfilled)
        console.log(`getListConversationAction success action=`, action);
        // let { items, total, page } = (action as any).payload;
        // state.conversationListState.items = items;
        // state.conversationListState.page = page + 1;
        // state.conversationListState.total = total || 0;
        // state.conversationListState.error = null;
        // state.conversationListState.loading = false;
      })
      .addCase(getListConversationAction.rejected, (state, action) => {
        //// Khi thực hiện action fetchCountFromApi (Promise rejected)
        // state.conversationListState.items = [];
        // state.conversationListState.total = 0;
        // state.conversationListState.error = action.payload;
        // state.conversationListState.loading = false;
        // console.log('getListConversationAction.rejected', action);
      });
  },
});

export const { setListConversationSuccessAction, setWebAppInfoSuccess, setAllChatBotSuccess } = appSlice.actions;
export const selectAppState = (state: RootState) => state.appState;
export const appReducer = appSlice.reducer;
