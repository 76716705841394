import { Badge, Button, Card, Divider, Space, Tag, Toast } from 'antd-mobile';
import Layout from '../../layout/layout';
import { AntOutline, RightOutline } from 'antd-mobile-icons';
import { srcScanCaptureAudio } from '../../consts/constApp';
import { playAudioFileName } from '../../utils/appHelper';

export default function Home() {
  return (
    <Layout>
      <div className="home-page">
        {/* <Space style={{ borderRadius: '10px' }}>
          <Tag color="default">Default</Tag>
          <Tag color="primary">测试</Tag>
          <Tag color="success">Success</Tag>
          <Tag color="warning">Warning</Tag>
          <Tag color="danger">Danger</Tag>
        </Space>
        <Divider>Divider</Divider> */}

        {/* <Space style={{ '--gap': '24px' }}>
          <Badge content="5">
            <span>AAAAAA</span>
          </Badge>
          <Badge content="新">
            <span>AAAAAA</span>
          </Badge>
          <Badge content={Badge.dot}>
            <span>AAAAAA</span>
          </Badge>
        </Space>
        <Divider>Divider</Divider> */}

        {Array.from(Array(5).keys()).map((r) => {
          return (
            <div key={r} style={{ background: '#f1f1f1', borderRadius: '10px' }}>
              <Card
                style={{ marginBottom: '30px', borderRadius: '16px' }}
                title={
                  <div style={{ fontWeight: 'normal' }}>
                    <AntOutline style={{ marginRight: '4px', color: '#1677ff' }} />
                    {r}
                  </div>
                }
                extra={<RightOutline />}
                onBodyClick={null}
                onHeaderClick={null}
              >
                <div className={'body'}>{r}</div>
                <div className={'footer'} onClick={(e) => e.stopPropagation()}>
                  <Button
                    color="primary"
                    onClick={() => {
                      // Toast.show(String(r).toString() as any);
                      playAudioFileName(srcScanCaptureAudio);
                      // playAudioUtil(srcScanCaptureAudio);
                    }}
                  >
                    底部按钮
                  </Button>
                </div>
              </Card>
            </div>
          );
        })}
      </div>
    </Layout>
  );
}
