import { Badge, Modal, NavBar, SideBar, Space, Toast } from 'antd-mobile';
import { Bottom } from './bottom';
import { CameraOutline, CloseOutline, HeartFill, HistogramOutline, LeftOutline, MoreOutline, SearchOutline, UserCircleOutline } from 'antd-mobile-icons';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { logWarn } from '../utils/logHelper';
import { LogoutOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../reduxs/store';
import { logoutAction } from '../reduxs/authSlice';

export default function Layout({ children }: any) {
  const authState = useSelector((state: any) => state.authState);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [sidebarTabs, setsidebarTabs] = useState([
    { key: 'item1', title: 'item1', icon: <HeartFill />, badge: Badge.dot, disabled: false },
    { key: 'item2', title: 'item2', icon: <HistogramOutline />, badge: '5' },
    { key: 'item3', title: 'item3', icon: <CameraOutline /> },
    {
      key: 'logout',
      title: 'Logout',
      icon: <LogoutOutlined />,
      onClick: () => {
        setIsOpenSidebar(false);
        Modal.confirm({
          title: 'Logout ?',
          confirmText: 'Yes',
          cancelText: 'No',
          onConfirm: () => {
            dispatch(logoutAction());
          },
          onCancel: () => {},
        });
      },
    },
  ]);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  const [titlePage, setTitlePage] = useState('Home');
  const [showBack, setshowBack] = useState(false);

  useEffect(() => {
    if (!authState.token || !authState.user) {
      logWarn(`empty token => to login page`, authState);
      navigate('/login');
    }
  }, [authState]);

  useEffect(() => {
    if (authState.user) {
      setsidebarTabs([
        {
          key: 'profile',
          title: <>{authState.user?.username || authState.user?.email}</>,
          icon: <UserCircleOutline />,
          disabled: false,
          onClick: () => {
            setIsOpenSidebar(false);
            navigate('/profile');
          },
        } as any,
        ,
        ...sidebarTabs,
      ]);
    }
  }, [authState?.user]);

  useEffect(() => {
    console.log(`route change`, location);
    let name = location?.pathname;
    setTitlePage(String(name).replace(/^\//, '') || 'Home');
    setshowBack(location?.pathname === '/' || location?.pathname === '/home' ? false : true);
  }, [location]);

  const backHandle = () => {
    // Toast.show({
    //   content: 'Back',
    //   duration: 1000,
    // });
    if (showBack === true) {
      navigate('/');
    }
  };

  return (
    <div className={'layout-app ' + (isOpenSidebar ? 'sidebar-open' : '')}>
      <div className={'layout-top'}>
        <NavBar
          back={showBack ? 'Back' : null}
          backIcon={showBack ? <LeftOutline /> : null}
          onBack={backHandle}
          right={
            <div style={{ fontSize: 24 }}>
              <Space style={{ '--gap': '16px' }}>
                <SearchOutline />
                {!isOpenSidebar ? (
                  <MoreOutline
                    onClick={(e) => {
                      setIsOpenSidebar(!isOpenSidebar);
                    }}
                  />
                ) : (
                  <CloseOutline
                    onClick={(e) => {
                      setIsOpenSidebar(!isOpenSidebar);
                    }}
                  />
                )}
              </Space>
            </div>
          }
        >
          <span style={{ textTransform: 'capitalize' }}>{titlePage}</span>
        </NavBar>
      </div>

      {isOpenSidebar && (
        <div className="sidebar-left">
          <SideBar defaultActiveKey={null} activeKey={null}>
            {sidebarTabs.map((item) => (
              <SideBar.Item
                key={item.key}
                title={
                  <span
                    onClick={(e) => {
                      item.onClick && item.onClick();
                    }}
                  >
                    {item.icon} {item.title}
                  </span>
                }
                badge={item.badge}
                disabled={item.disabled}
              />
            ))}
          </SideBar>
        </div>
      )}

      <div className={'layout-body'}>{children}</div>
      <div className={'layout-bottom'}>
        <Bottom />
      </div>
    </div>
  );
}
