import { TabBar } from 'antd-mobile';
import { AppOutline, MessageOutline, UnorderedListOutline, UserOutline } from 'antd-mobile-icons';
import { useNavigate, useLocation } from 'react-router-dom';

export const Bottom = () => {
  const history = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const setRouteActive = (value: string) => {
    // window['customAudio'] = window['customAudio'] || document.createElement('audio');
    // window['customAudio'].src = '';
    history(value);
  };

  const tabsBottom = [
    {
      key: '/home',
      title: 'Home',
      icon: <AppOutline />,
    },
    {
      key: '/todo',
      title: 'Todo',
      icon: <UnorderedListOutline />,
    },
    {
      key: '/message',
      title: 'Message',
      icon: <MessageOutline />,
    },
    {
      key: '/profile',
      title: 'Profile',
      icon: <UserOutline />,
    },
    // {
    //   key: "/me",
    //   title: "Profile",
    //   icon: <UserOutline />,
    // },
  ];

  return (
    <TabBar activeKey={pathname} onChange={(value) => setRouteActive(value)}>
      {tabsBottom.map((item) => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  );
};
