import { API_URL } from './../consts/constApp';
import { getAuthToken } from '../utils/authHelper';
import { apiClient } from './axiosBase';

export const loginGoogleApi = async () => {
  window.location.href = API_URL + '/auth/google';
  return;
  // try {
  //   const result = await apiClient.get("/auth/google");
  //   console.log(result);
  // } catch (error) {
  //   console.log(error);
  //   throw error;
  // }
};
export const getUserInfo = async ({ token }: any) => {
  if (!token) {
    token = getAuthToken();
  }
  // console.log(`token`, token);
  try {
    const result = await apiClient.get('/auth/user', {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    // console.log(`getUserInfo api`, result);
    return result?.data?.data;
  } catch (error) {
    console.error(`getUserInfo err`, error);
    throw error;
  }
};
export const loginByUsernameApi = async ({ username, password }: any) => {
  try {
    const result = await apiClient.post(
      '/public/authenticate',
      {
        username,
        email: username,
        password,
      },
      {
        headers: {
          // authorization: `Bearer ${token}`,
        },
      }
    );

    let token = result?.data?.Authorization;
    let user = result?.data?.user;
    let permissions = result?.data?.permissions;
    // console.log(`getUserInfo api`, result);
    // return result?.data?.data;
    console.log(`loginByUsernameApi api`, result, {
      token,
      user,
      permissions,
    });
    return {
      token,
      user,
      permissions,
    };
  } catch (error) {
    console.error(`loginByUsernameApi err `, error);
    throw error;
  }
};
