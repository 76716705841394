import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from './store';
import { getUserInfo, loginByUsernameApi } from '../apis/authApi';
import { getAuthToken, getAuthUser, initAuthStateRedux, removeLocalStorageAndCookies, setLocalStorageAndCookies } from '../utils/authHelper';
import Cookies from 'js-cookie';

export interface AuthState {
  token?: string;
  user?: any;
  permissions?: any;
  loading?: boolean;
  error?: any;
}
const initStateFromCache = initAuthStateRedux();
const initialState: AuthState = {
  token: initStateFromCache.token,
  user: initStateFromCache.user,
  permissions: initStateFromCache.permissions,
  loading: false,
  error: null,
};

export const loginByUserPassThunk = createAsyncThunk('auth/loginByUserPass', async ({ username, password }: any, { rejectWithValue }) => {
  try {
    const response = await loginByUsernameApi({ username, password });
    // console.log(`loginByUserPassThunk login success`, response);
    return response;
  } catch (error) {
    console.error(`loginByUserPassThunk login error`, error);
    return rejectWithValue(error);
  }
});

export const getUserInfoThunkAction = createAsyncThunk(
  'auth/getUserInfoThunk', //ten action
  async (params: any = {}, { rejectWithValue }) => {
    let token = params.token;
    try {
      const response = await getUserInfo({ token });
      // console.log(`getUserInfoThunkAction success`, response);
      return { token, user: response };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,

  // Các action bình thường (sync action)
  reducers: {
    setUserProfile: (state, action: PayloadAction<any>) => {
      // console.log(`login success`, action);
      state.user = action.payload;
      state.loading = false;
      setLocalStorageAndCookies({ user: action.payload });
    },
    loginSuccess: (state, action: PayloadAction<any>) => {
      // console.log(`login success`, action);
      state.token = action.payload?.token;
      state.user = action.payload?.user;
      state.permissions = action.payload?.permissions;
      state.loading = false;
    },
    loginError: (state, action: PayloadAction<any>) => {
      state.token = '';
      state.user = null;
      state.loading = false;
    },
    logoutAction: (state) => {
      state.token = '';
      state.user = null;
      state.loading = false;
      state.permissions = null;
      state.error = null;
      removeLocalStorageAndCookies();
    },
    setErrorEmpty: (state) => {
      state.error = null;
      state.loading = false;
    },
  },

  // action async - Code logic xử lý async action
  extraReducers: (builder) => {
    builder
      .addCase(loginByUserPassThunk.pending, (state) => {
        //// Bắt đầu thực hiện action fetchCountFromApi (Promise pending)
        state.loading = true;
      })
      .addCase(loginByUserPassThunk.fulfilled, (state, action) => {
        //// Khi thực hiện action fetchCountFromApi (Promise fulfilled)
        console.log(`login success action=`, action);
        let { token, user, permissions } = (action as any)?.payload;
        state.loading = false;
        state.user = user;
        state.token = token;
        state.permissions = permissions;
        setLocalStorageAndCookies({ user, token, permissions });
      })
      .addCase(loginByUserPassThunk.rejected, (state, action: any) => {
        //// Khi thực hiện action fetchCountFromApi (Promise rejected)
        state.loading = false;
        state.user = null;
        state.token = null;
        state.permissions = null;
        state.error = action.payload?.message || 'Username or password is incorrect.';
        console.error('loginByUserPassThunk.rejected', action);
      });

    builder
      .addCase(getUserInfoThunkAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getUserInfoThunkAction.fulfilled, (state, action: any) => {
        state.loading = false;
        let { token, user } = action.payload;
        if (token) {
          state.token = token;
          setLocalStorageAndCookies({ token });
        }
        if (user) {
          state.user = user;
          setLocalStorageAndCookies({ user });
        }
      })
      .addCase(getUserInfoThunkAction.rejected, (state, action) => {
        state.loading = false;
        state.user = null;
        state.token = '';
        state.error = action.payload;
        console.error('Error getUserInfoThunkAction.rejected', action);
      });
  },
});

export const { loginSuccess, loginError, logoutAction, setErrorEmpty, setUserProfile } = authSlice.actions;
export const selectAuthState = (state: RootState) => state.authState;
export const authReducer = authSlice.reducer;
