import { LoginOutlined } from '@ant-design/icons';
import { Avatar, Button, DotLoading, Form, Input, NavBar, Space } from 'antd-mobile';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logSuccess, logWarn } from '../../utils/logHelper';
import { useEffect } from 'react';
import { AuthState, loginByUserPassThunk } from '../../reduxs/authSlice';
import { AppDispatch, useAppDispatch } from '../../reduxs/store';
import { isLocalhost } from '../../utils/appHelper';
import LoadingOverlay from '../../components/loading';

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authState: AuthState = useSelector((state: any) => state.authState);

  const onSubmitLogin = async (values) => {
    console.log(values);
    dispatch(
      loginByUserPassThunk({
        username: isLocalhost() ? 'nguyentvk@gmail.com' : values.username,
        password: isLocalhost() ? 'nguyentvk' : values.password,
      })
    );
  };

  useEffect(() => {
    if (authState.token && authState.user) {
      logSuccess(`has token => to home page`, authState);
      navigate('/');
    }
  }, [authState]);
  return (
    <div>
      {/* {true && <LoadingOverlay />} */}
      <div className="w-100 text-center" style={{ width: '100%', textAlign: 'center' }}>
        <img className="text-center center login-avatar" src="images/logo/logo.png" />
      </div>
      <div className="w-100 text-center" style={{ width: '100%', textAlign: 'center', marginBottom: '10px' }}>
        <NavBar
          // back="Back"
          backIcon={null}
          onBack={null}
          // right={
          //   <div style={{ fontSize: 24 }}>
          //     <Space style={{ '--gap': '16px' }}>
          //       <SearchOutline />
          //       {!isOpenSidebar ? (
          //         <MoreOutline
          //           onClick={(e) => {
          //             setIsOpenSidebar(!isOpenSidebar);
          //           }}
          //         />
          //       ) : (
          //         <CloseOutline
          //           onClick={(e) => {
          //             setIsOpenSidebar(!isOpenSidebar);
          //           }}
          //         />
          //       )}
          //     </Space>
          //   </div>
          // }
        >
          <span style={{ fontSize: '36px', fontWeight: 'bold' }}>Login</span>
        </NavBar>
      </div>

      <Form
        onFinish={onSubmitLogin}
        footer={
          <Button block type="submit" color="primary" size="large" disabled={authState.loading}>
            {authState.loading ? <DotLoading /> : <LoginOutlined />}
            <span className="ml-2" style={{ marginLeft: '10px' }}>
              Login
            </span>
          </Button>
        }
      >
        {/* <Form.Header>
        Login
        </Form.Header> */}

        <Form.Item
          name="username"
          label="Username"
          rules={[
            { required: true, message: 'Username is required' },
            // { type: 'string', min: 6 },
            // { type: 'email', warningOnly: true },
          ]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            { required: true, message: 'Password is required' },
            // { type: 'string', min: 6 },
            // { type: 'email', warningOnly: true },
          ]}
        >
          <Input placeholder="Password" type="password" />
        </Form.Item>
      </Form>
    </div>
  );
}
